import Alert from '@mui/material/Alert';

//const {createAlchemyWeb3} = require('@alch/alchemy-web3');
//const web3 = createAlchemyWeb3(process.env.ALCHEMY_PUBLIC_API_KEY);
//const contract = require('./artifacts/contracts/.sol/.json')
const contractAddress = process.env.CONTRACT_ADDRESS;
//const nftContract = new web3.eth.Contract(contract.abi,contractAddress);

export const ConnectWallet = async() =>{
    if (window.ethereum){
        try{
            const addressArray = await window.ethereum.request({method:"eth_requestAccounts",})
            return {
                address : addressArray[0],
                status : <Alert sx={ { alignItems: 'center' }} severity="success"><strong>{'Connected to ' + String(addressArray[0])}</strong></Alert>,
            }
        }catch (err){
            return {
                address : "",
                status : <Alert severity="error">err.message</Alert>,
            }
        }
    }else{
        return {
            address:'',
            status:(
                <span>
                    <p>
                        <a href={"https://metamask.io/download.html"}>
                            Install Metamask.
                        </a>
                    </p>
                </span>
            )
        }
    }
}

export const GetConnectedWallet = async ()=>{
    if (window.ethereum){
        try{
            const addressArray = await window.ethereum.request({method:"eth_accounts",})
            if (addressArray.length>0) {
                return {
                    address: addressArray[0],
                    status: <Alert sx={ { alignItems: 'center' }} severity="success"><strong>{'Connected to ' + String(addressArray[0])}</strong></Alert>,
                }
            }else{
                return {
                    address : '',
                    status : <Alert severity="warning">Connect to 🦊 Metamask to mint.</Alert>,
                }
            }
        }catch (err){
            return {
                address : '',
                status : <Alert severity="error">err.message</Alert>,
            }
        }
    }else{
        return {
            address:'',
            status:<Alert severity="warning">Install 🦊 Metamask to mint.</Alert>,
        }
    }
}
