import React, { useState,useEffect } from 'react';
import HoverImage from "react-hover-image";
import './App.css';
import dcImage from './images/buttons/discord.png';
import dcHoverImage from './images/buttons/discord_hover.png';
import twitterImage from './images/buttons/twitter.png';
import twitterHoverImage from './images/buttons/twitter_hover.png';
import mediumImage from './images/buttons/medium.png';
import mediumHoverImage from './images/buttons/medium_hover.png';
import openseaImage from './images/buttons/opensea.png';
import openseaHoverImage from './images/buttons/opensea_hover.png';
import roadMapImage from './images/roadMap.png';
import championsGifImage from './images/champions.gif'
import soundOn from './images/buttons/soundOn.png';
import soundOff from './images/buttons/soundOff.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import { alpha } from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ConnectWallet , GetConnectedWallet} from "./interact";
import Alert from "@mui/material/Alert";
import music from './audio/music.mp3';

//const pages = ['Home', 'Whitepaper','Roadmap', 'FAQ', 'My Champions'];
const pages = ['Home', 'FAQ', 'My Champions'];

let clicked = false;

const isFirefox = (navigator.userAgent.indexOf("Firefox") != -1 )


const handleClick = () => {
    clicked = true;
    document.removeEventListener('click', handleClick, false);
    let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    let xhr = new XMLHttpRequest();
    xhr.open('GET', music);
    xhr.responseType = 'arraybuffer';
    xhr.addEventListener('load', () => {
        let playsound = (audioBuffer) => {
            let source = audioCtx.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioCtx.destination);
            source.loop = true;
            source.start();
        };
        audioCtx.decodeAudioData(xhr.response).then(playsound);
    });
    xhr.send();
};

if (!isFirefox) {
    document.addEventListener('click', handleClick, false);
}

window.addEventListener('load', () => {
    if (isFirefox){
        handleClick();
    }
});

const Accordion = styled(MuiAccordion)(({ theme }) => ({
    backgroundColor: alpha('#552d44',0.8),
    borderWidth: '2px',
    '&:hover': {
        backgroundColor: '#351e2b',
    },
    '&:expanded': {
        backgroundColor: '#351e2b',
    },
}));



const ColorButton = styled(Button)(({ theme }) => ({
    borderColor: '#29151b',
    backgroundColor: alpha('#552d44',0.8),
    borderWidth: '2px',
    '&:hover': {
        borderColor: alpha('#552d44',0.4),
    },
}));


const SetItem = styled(Paper)(({ theme }) => ({
    height:'25px',
    backgroundColor: alpha('#29151b', 0.9),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    fillOpacity:0.1
}));

const SetItemXS = styled(Paper)(({ theme }) => ({
    height:'80px',
    backgroundColor: alpha('#29151b', 0.9),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    fillOpacity:0.1
}));

const Item = styled(Paper)(({ theme }) => ({
    height:'100px',
    backgroundColor: alpha('#29151b', 0.9),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fillOpacity:0.1,
    paddingBottom:"30px"
}));

const Item2 = styled(Paper)(({ theme }) => ({
    height:'30px',
    backgroundColor: alpha('#29151b', 0.9),
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fillOpacity:0.1,
}));

const Item3 = styled(Paper)(({ theme }) => ({
    height:'30px',
    backgroundColor: alpha('#29151b', 0.9),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fillOpacity:0.1,
}));

const setData = [
    {
        name: "Leather",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: false,
        weaponEnchanted: false,
    },{
        name: "Scoundrel",
        hasTorso: true,
        hasLeg: false,
        hasWeapon: false,
        weaponEnchanted: false,
    },{
        name: "Commoner",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: true,
    },{
        name: "Halfplate",
        hasTorso: false,
        hasLeg: false,
        hasWeapon: true,
        weaponEnchanted: false,
    },{
        name: "Swashbuckler",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: true,
    },{
        name: "Monk",
        hasTorso: false,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: true,
    },{
        name: "Brute",
        hasTorso: false,
        hasLeg: true,
        hasWeapon: false,
        weaponEnchanted: true,
    },{
        name: "Vanguard",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: false,
    },{
        name: "Bone Collector",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: true,
    },{
        name: "Bark",
        hasTorso: false,
        hasLeg: false,
        hasWeapon: false,
        weaponEnchanted: false,
    },{
        name: "Royal",
        hasTorso: true,
        hasLeg: false,
        hasWeapon: false,
        weaponEnchanted: true,
    },{
        name: "Stoneshard",
        hasTorso: false,
        hasLeg: false,
        hasWeapon: true,
        weaponEnchanted: true,
    },{
        name: "Dark Knight",
        hasTorso: false,
        hasLeg: true,
        hasWeapon: false,
        weaponEnchanted: false,
    },{
        name: "Windwalker",
        hasTorso: true,
        hasLeg: true,
        hasWeapon: false,
        weaponEnchanted: true,
    },{
        name: "Bloodfang",
        hasTorso: false,
        hasLeg: true,
        hasWeapon: true,
        weaponEnchanted: true,
    },
];

const itemData = [
    {
        img: 'https://gateway.pinata.cloud/ipfs/QmYUHjjAMuNQ2Y9rvLVT2iMrGGnQLHPrQj7QWyvqaBvFyH',
        title: '1',
    },
    {
        img: 'https://gateway.pinata.cloud/ipfs/QmWor1yqoKMBrd4rFinuPCBP71nFC6cDAuk2dkSqXbQWiQ',
        title: '2',
    },
    {
        img: 'https://gateway.pinata.cloud/ipfs/QmQhX9AtzX5E9wvQZ3oG6UC9iwuDGDC6eGDLhNv4P4tcCB',
        title: '3',
    },
    {
        img: 'https://gateway.pinata.cloud/ipfs/QmbkTAFoEuPW9cHuxf2P16Qih73yLx16EHGf4so7u2QvBp',
        title: '4',
    },
    {
        img: 'https://gateway.pinata.cloud/ipfs/QmPJxNDV5XaPvzBKRnBQtpZGcWdAKW6nXcrMuJ8yW4f6xC',
        title: '5',
    },
];

function App() {
    const [WalletAddress , SetWalletAddress] = useState('');
    const [SoundPlaying, SetSoundPlaying] = useState(true);
    const [Status,SetStatus] = useState('');

    const ConnectWalletPressed = async() =>{
        if (!window.ethereum){
            window.open("https://metamask.io/download.html","_self")
        }else if (WalletAddress.length>0 ) {
            alert("Coming Soon!");
        }else{
            const walletResponse = await ConnectWallet();
            SetWalletAddress(walletResponse.address);
            SetStatus(walletResponse.status);
        }
    }

    const AddWalletListener = () => {
        if (window.ethereum){
            window.ethereum.on("accountsChanged",async (accounts)=>{
                if (accounts.length>0){
                    SetWalletAddress(accounts[0]);
                    let alert = <Alert sx={ { alignItems: 'center' }} severity="success"><strong>{'Connected to ' + String(accounts[0])}</strong></Alert>
                    SetStatus(alert);
                }else{
                    SetWalletAddress("");
                    SetStatus(<Alert severity="warning">Connect to 🦊 Metamask to mint.</Alert>);
                }
            });
        }
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const toggleSound = () =>{
        SetSoundPlaying(!SoundPlaying);


    }

    const [currentPage, setCurrentPage] = useState('Home');
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const claim = () => {
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = (page) => {
        setAnchorElNav(null);
        if (page === "Whitepaper") {
            window.open("https://whitepaper.nuldaeron.com/", "_blank");
        } else {
            setCurrentPage(page);
            if (page === "Home"){
                document.body.className = "fullBg";
                document.getElementById("footer").className = "absoluteBottom";
            }else if (page === "My Champions"){
                document.body.className = "blurredBg";
                document.getElementById("footer").className = "bottom";
            }else{
                document.body.className = "blurredBg";
                document.getElementById("footer").className = "bottom";
            }
        }
    };

    const closeNavMenu = (page) => {
        setAnchorElNav(null);
    }


    useEffect(async()=>{
        handleCloseNavMenu(currentPage);
        const {address, status} = await GetConnectedWallet();
        SetWalletAddress(address)
        SetStatus(status);
        AddWalletListener();
    }, [currentPage]);

        return (
            <div>

                <div className="overall"/>
                <AppBar  elevation= {0} position="static" color='transparent'>
                    <Container maxWidth='100%'>
                        <Toolbar disableGutters>
                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'block', lg:'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClick={() => closeNavMenu()}
                                    sx={{
                                        display: {xs: 'block', md: 'block',lg:'none'},
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={"navMenu_"+page} onClick={() => handleCloseNavMenu(page)}>
                                            <Typography
                                                fontFamily='Minecraft-Regular'
                                                textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <Box
                                sx={{flexGrow: 1, display: {xs: 'none', md: 'none', lg:'flex'}}}>
                                {pages.map((page) => (
                                    <ColorButton size="small"
                                                 variant="outlined"
                                                 style={{fontFamily: 'Minecraft-Regular'}}
                                                 key={"nav_"+page}
                                                 onClick={() => handleCloseNavMenu(page)}
                                                 sx={{margin: 1, my: 2, color: 'white', display: 'block'}}
                                    >
                                        <h2>{page}</h2>
                                    </ColorButton>
                                ))}
                            </Box>

                            <Box sx={{flexGrow: 0}}>
                                <div style={{height:'50px'}} className="grid-container-header">
                                    <div style={{height:'44px'}} className="grid-item-header"><HoverImage src={dcImage}
                                                                                  hoverSrc={dcHoverImage}/></div>
                                    <div style={{height:'44px'}} className="grid-item-header"><a
                                        href="https://twitter.com/nuldaeron"><HoverImage src={twitterImage}
                                                                                         hoverSrc={twitterHoverImage}/></a>
                                    </div>
                                    <div style={{height:'44px'}} className="grid-item-header"><HoverImage src={mediumImage}
                                                                                  hoverSrc={mediumHoverImage}/></div>
                                    <div style={{height:'44px'}} className="grid-item-header"><HoverImage src={openseaImage}
                                                                                  hoverSrc={openseaHoverImage}/></div>
                                </div>
                            </Box>
                            <Box
                                sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>

                                    <ColorButton size="small"
                                                 variant="outlined"
                                                 style={{fontFamily: 'Minecraft-Regular'}}
                                                 key={"connectWallet"}
                                                  onClick={ConnectWalletPressed}
                                                 sx={{margin: 1, my: 2, color: 'white', display: 'block'}}
                                    >
                                        <h2>{
                                            !window.ethereum ? (
                                                <span>Install Metamask</span>
                                            ) : (
                                                WalletAddress.length>0 ? (
                                                    WalletAddress.slice(0,6) + '...' + WalletAddress.slice(-4)
                                                ) : (
                                                    <span>CONNECT WALLET</span>
                                                )
                                            )
                                        }</h2>
                                    </ColorButton>
                                <div>
                                </div>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>

                {currentPage === 'My Champions' ? (
                    <div>
                <div style={{opacity:'20%'}} className="main">
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid item xs={12} sm={12} md={10}>
                            <Item2><h4>Claimable <span className="won">$WON:</span> <span className="white">293128</span>
                            </h4></Item2>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <Item3><ColorButton variant="outlined"
                                                style={{fontFamily: 'Minecraft-Regular'}}
                                                onClick={claim}
                                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                <h2>Claim</h2>
                            </ColorButton></Item3>

                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container  rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={6} sm={6} md={3}>
                            <Item><h1>5/18</h1><br/><h2>CHAMPIONS</h2><h2>in war</h2></Item>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Item><h1>16934</h1><br/><h2>TOTAL</h2><h2>Combat Power</h2></Item>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Item><h1>+%44</h1><br/><h2>BONUS from</h2><h2>Team Chemistry</h2></Item>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Item><h1>24384</h1><br/><h2><span className="won">$WON</span> generation</h2><h2>per
                                day</h2></Item>
                        </Grid>
                    </Grid>
                    <ImageList sx={{
                        gridAutoFlow: "column",
                        gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr)) !important",
                        gridAutoColumns: "minmax(300px, 1fr)"

                    }}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.title}>
                                <img
                                    style={{borderRadius: '2%'}}
                                    src={`${item.img}?w=150&h=150&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />

                            </ImageListItem>


                        ))}
                    </ImageList>
                    <Grid container rowSpacing={2} columnSpacing={2}
                          sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}>
                        {setData.map((set) => (
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <SetItem>
                                    <Stack sx={{marginTop: '-10px'}} justifyContent="space-between" alignItems="center"
                                           direction="row" spacing={5}>
                                        <div style={{minWidth: '150px'}}><h2>{set.name} Set: </h2></div>
                                        <Stack sx={{marginTop: '-10px'}} justifyContent="center" alignItems="center"
                                               direction="row" spacing={1}>
                                            <Chip label="T"
                                                  color={set.hasTorso ? (set.hasLeg ? "success" : "primary") : "default"}/>
                                            <Chip label="L"
                                                  color={set.hasLeg ? (set.hasTorso ? "success" : "primary") : "default"}/>
                                            <Chip label="W"
                                                  color={set.hasWeapon ? (set.hasLeg && set.hasTorso ? "success" : "primary") : "default"}/>
                                            <Chip label="E"
                                                  color={set.weaponEnchanted ? (set.hasTorso && set.hasLeg && set.hasWeapon ? "success" : "primary") : "default"}/>
                                            <span/>
                                            <span/>
                                            <div style={{width: '100px'}}>
                                                <h1>+%{(set.hasTorso && set.hasLeg) ? (set.hasWeapon ? (set.weaponEnchanted ? 10 : 8) : 3) : 0}</h1>
                                            </div>
                                        </Stack>
                                    </Stack>
                                    <br/>
                                </SetItem>
                                <Box sx={{width: '100%'}}>
                                    <LinearProgress color={set.hasTorso && set.hasLeg ? "success" : "primary"}
                                                    variant="determinate"
                                                    value={((set.hasTorso ? 25 : 0) + (set.hasLeg ? 25 : 0) + (set.hasWeapon ? 25 : 0) + (set.weaponEnchanted ? 25 : 0))}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={2}
                          sx={{flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}>
                        {setData.map((set) => (
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <SetItemXS>
                                    <h2>{set.name} Set: </h2>
                                    <br/>
                                    <Stack sx={{marginTop: '-10px'}} justifyContent="space-between" alignItems="center"
                                           direction="row" spacing={5}>
                                        <Stack sx={{marginTop: '-10px'}} justifyContent="center" alignItems="center"
                                               direction="row" spacing={1}>
                                            <Chip label="T"
                                                  color={set.hasTorso ? (set.hasLeg ? "success" : "primary") : "default"}/>
                                            <Chip label="L"
                                                  color={set.hasLeg ? (set.hasTorso ? "success" : "primary") : "default"}/>
                                            <Chip label="W"
                                                  color={set.hasWeapon ? (set.hasLeg && set.hasTorso ? "success" : "primary") : "default"}/>
                                            <Chip label="E"
                                                  color={set.weaponEnchanted ? (set.hasTorso && set.hasLeg && set.hasWeapon ? "success" : "primary") : "default"}/>
                                        </Stack>
                                        <div style={{width: '100px'}}>
                                            <h1>+%{(set.hasTorso && set.hasLeg) ? (set.hasWeapon ? (set.weaponEnchanted ? 10 : 8) : 3) : 0}</h1>
                                        </div>

                                    </Stack>
                                    <br/>
                                </SetItemXS>
                                <Box sx={{width: '100%'}}>
                                    <LinearProgress color={set.hasTorso && set.hasLeg ? "success" : "primary"}
                                                    variant="determinate"
                                                    value={((set.hasTorso ? 25 : 0) + (set.hasLeg ? 25 : 0) + (set.hasWeapon ? 25 : 0) + (set.weaponEnchanted ? 25 : 0))}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                        <div className='centeredCS'>
                            <h5 style={{fontSize:'60px'}}>COMING SOON!</h5>
                            <h5>Champion management system</h5>
                            <h5>Available right after mint</h5>
                        </div>
                        </div>
                        ) : currentPage === 'Mint' ? (
                            <div>
                        <div style={{opacity:'100%'}} className="main">
                            <Box className=""
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 550,
                                    height : 1000,
                                    alignItems: 'center',
                                    bgcolor: '#351e2b',
                                    overflow: 'hidden',
                                    borderRadius: '12px',
                                    boxShadow: 5,
                                    fontWeight: 'bold',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'papayawhip',
                                        overflow: 'hidden',
                                        borderRadius: '12px',
                                        boxShadow: 12,
                                        my: 2
                                    }}
                                >
                                    <Typography sx={{mx: 1.5}} style={{color: '#351e2b',fontFamily: 'Minecraft-Regular', fontSize:40}}>
                                        PRE-SALE
                                    </Typography>
                                </Box>
                                <div>
                                    <div>
                                        <Box component="img" sx={{height: 500, width: 500,boxShadow: 5,borderRadius: '12px'}} src={championsGifImage}>

                                        </Box>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        color: 'white',
                                        top: 25,
                                    }} >
                                        <Box
                                            sx={{
                                                bgcolor: '#351e2b',
                                                overflow: 'hidden',
                                                borderRadius: '12px',
                                                boxShadow: 12,
                                                fontWeight: 'bold',
                                                margin: 2,
                                                my: 12
                                            }}
                                        >
                                            <Typography sx={{mx: 1.5, my:0.5}} style={{color: 'papayawhip',fontFamily: 'Minecraft-Regular', fontSize:25,fontWeight:'bold'}}>
                                                1389/4444
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>

                                <Box
                                    sx={{
                                        bgcolor: 'papayawhip',
                                        overflow: 'hidden',
                                        borderRadius: '12px',
                                        boxShadow: 12,
                                        fontWeight: 'bold',
                                        my: 2
                                    }}
                                >
                                    <Typography sx={{mx: 1.5}} style={{color: '#351e2b',fontFamily: 'Minecraft-Regular', fontSize:40}}>
                                        Price: 0.1 ETH + GAS
                                    </Typography>
                                </Box>

                                <div>
                                    <ColorButton size="large"
                                                 variant="outlined"
                                                 style={{fontFamily: 'Minecraft-Regular'}}
                                        // onClick={() => handleCloseNavMenu(page)}
                                                 sx={{width: 130, height:70,margin: 1, my: 2,color: 'papayawhip', display: 'inline',borderRadius: '12px', fontSize:27, fontWeight:'bold'}}
                                    >
                                        MINT
                                    </ColorButton><ColorButton size="large"
                                                               variant="outlined"
                                                               style={{fontFamily: 'Minecraft-Regular'}}
                                    // onClick={() => handleCloseNavMenu(page)}
                                        sx={{width: 250, height:70,margin: 1, my: 2,  color: 'papayawhip', display: 'inline',borderRadius: '12px', fontSize:27, fontWeight:'bold'}}
                                     >
                                        MINT & STAKE
                                    </ColorButton>
                                </div>
                                <Divider
                                    flexItem = 'true'
                                    variant= 'middle'
                                    sx={{
                                        my: 7,
                                        mx: 5,
                                        color: 'white'
                                    }} />
                                <div>
                                    <Typography align="center" sx={{}} style={{color: 'papayawhip',fontFamily: 'Minecraft-Regular', fontSize:40}}>
                                        Contract
                                    </Typography>
                                    <Typography align="center" sx={{}} style={{color: 'papayawhip',fontFamily: 'Minecraft-Regular', fontSize:15}}>
                                        0x110Eae3EF426b8a3eb4CeF43E7c189194e2Ee480
                                    </Typography>
                                </div>
                            </Box>
                        </div>
                            </div>
                ) : currentPage === "Home" ?
                    (
                        <div className="centered">
                            <h5>An ethereum based MMORPG with unique <span className="red">PVP</span> gameplay</h5>
                            {/*
                            <div className="container">
                                <a href = 'https://whitepaper.nuldaeron.com/'><HoverImage src={buttonFrame} hoverSrc={buttonFrameHover}></HoverImage></a>
                            </div>
                            */}
                        </div>
                    ) :currentPage === "FAQ" ?
                        (
                         <div className="main">
                             <h1>FAQ</h1>
                             <br/>
                             <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                 <AccordionSummary sx = {{bgcolor : 'success'}}
                                                   expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHAT IS A CHAMPION?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         Champions are unique fighters from different clans of Varstem.
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHEN IS THE MINT DATE?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         TBA
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion >
                             <br/>
                             <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHAT IS THE MINT PRICE?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         TBA
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>HOW MANY I CAN MINT?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         TBA
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>HOW CAN I GET WHITELISTED FOR THE PRESALE?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         Join our discord, follow our twitter, be a part of the community.
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHAT IS NEVER-ENDING-WAR?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         Never-ending-war is a tradition where Champions from all clans fight to bring fame, glory and most importantly $WON for their holders. Every Champion you send to Never-ending-war passively generates $WON for you, and you can optimise your earnings by strategically handpicking the Champions you’ll send trying to get bonuses via the Team Chemistry system.
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHAT IS $WON?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         $WON is the main currency of the World of Nuldaeron. At Stage 1 it will only be earned from Never-ending-war with the help of Champions. Holders will be able to spend their $WON in a variety of ways. A non-exclusive list of examples;
                                         <br/>-Whitelist access and minting of our every new NFT collection.
                                         <br/>-Entering the open-world of Varstem
                                         <br/>-Acquiring items to use in pvp and pve.
                                         -Acquiring companions and land.
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>
                             <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                 <AccordionSummary
                                     expandIcon={<ExpandMoreIcon />}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                 >
                                     <h3>WHAT IS TEAM CHEMISTRY?</h3>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                     <div style={{marginRight:'40px',marginBottom:'20px',marginTop:'-20px',marginLeft:'40px'}}><h5>
                                         Team Chemistry determines the percentage of the bonus you get to your daily $WON revenue. Strong teams are more than just a collection of Champions with the rarest attributes. If you want to optimise your $WON income, you have to be able to combine the attributes of your Champions strategically.
                                     </h5></div>
                                 </AccordionDetails>
                             </Accordion>
                             <br/>


                         </div>
                        ) : currentPage == "Roadmap" ?
                            (
                                <div className='centerImg'>
                                    <h1 className='textShadow'>ROADMAP</h1>
                                    <img className='roadMap' src={roadMapImage}/>
                                </div>
                            ): ""

                }

                <div id="footer" className="bottom">
                    <h5></h5>
                    <h6>Copyright ©2022 World of Nuldaeron®</h6>
                </div>
                {
                    /*
                    SoundPlaying ?
                    (
                        <div className='sound'>
                            <img onClick={toggleSound} alt='soundOn' src={soundOn}/>
                        </div>
                    ) :
                    (
                    <div className='sound'>
                        <img onClick={toggleSound} alt='soundOff'  src={soundOff} />
                    </div>
                    )

                     */
                }

            </div>

        );

}
export default App;